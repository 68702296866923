export function useScrollEvent(
  $block: Ref<ComponentPublicInstance | undefined>,
  event: () => void,
) {
  const isVisible = useElementVisibility($block);

  const unwatch = watch(() => isVisible.value, (value) => {
    if (value) {
      event();
      unwatch();
    }
  }, { immediate: true });

  return {
    isVisible,
  };
}

export function useItemsScrollEvent(
  $itemRefs: Ref<HTMLElement[]>,
  event: (index: number) => void,
) {
  watch($itemRefs, (items) => {
    if (!items.length) return;

    items.forEach((item, index) => {
      const { stop } = useIntersectionObserver(
        item,
        ([{ isIntersecting }]) => {
          if (isIntersecting) {
            event(index);
            stop();
          }
        },
        { threshold: 0.8 },
      );
    });
  }, { deep: true });

  return {
    $itemRefs,
  };
}
