<template>
  <SwiperSlide
    :tag="tag"
    :virtual-index="virtualIndex"
    :zoom="zoom"
  >
    <slot />
  </SwiperSlide>
</template>

<script lang="ts">
// eslint-disable-next-line import/no-unresolved
import { SwiperSlide } from 'swiper/vue';

export default {
  name: 'SwiperSlide',
};
</script>

<script lang="ts" setup>
interface Props {
  tag?: string
  swiperRef?: object
  virtualIndex?: string | number
  zoom?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  tag: 'div',
  swiperRef: () => ({}),
  virtualIndex: undefined,
  zoom: undefined,
});
</script>
