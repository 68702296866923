import {
  SwiperOptions,
  Swiper as SwiperClass,
} from 'swiper/types';

import { AnalyticsEvents } from '~/types/analytics';

type TSwiper = Ref<{
  swiper?: SwiperClass;
}>

interface IOptions {
  chapter?: string
  id_chapter?: string
  item?: string
  chapter_index?: number
  segment_id?: number
}

interface ISwiperOptions extends SwiperOptions {
  off?: boolean
}

interface IBreakpoints {
  [width: number]: ISwiperOptions;
  [ratio: string]: ISwiperOptions;
}

export function useSwiperAnalytics(
  $swiper: TSwiper,
  $block: Ref<ComponentPublicInstance | undefined>,
  allIds: Ref<(string | number)[] | (string | number)[][]>,
  options: IOptions,
  sliderBreakpoints?: IBreakpoints,
  isUse = true,

) {
  if (!isUse) {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const empty = () => {};

    return {
      event: empty,
      clickEvent: empty,
    };
  }

  const { $addEvent } = useNuxtApp();

  const event = sendEvent();

  const { isVisible } = useScrollEvent($block, event);

  function sendEvent() {
    const sendedIndexes = new Set();
    const sendedIds = new Set();

    return () => {
      if (!isVisible.value || !$swiper.value?.swiper) return;

      const { activeIndex, currentBreakpoint } = $swiper.value.swiper;

      if (sendedIndexes.has(activeIndex)) return;

      sendedIndexes.add(activeIndex);

      if (sliderBreakpoints) {
        const slidesPerView = (sliderBreakpoints[currentBreakpoint].slidesPerView ?? 1) as number;

        const count = Math.ceil(slidesPerView);
        const ids = allIds.value.slice(activeIndex, activeIndex + count)
          .filter((id) => !sendedIds.has(id)) as string[] | string[][];

        ids.forEach((id) => sendedIds.add(id));

        const idsString = (ids.reduce((result, id) => {
          if (Array.isArray(id)) {
            id.forEach((item) => {
              // eslint-disable-next-line no-param-reassign
              result += `${item};`;
            });

            return result;
          }

          // eslint-disable-next-line no-param-reassign
          result += `${id};`;

          return result;
        }, '') as string).slice(0, -1);

        if (idsString) {
          $addEvent(AnalyticsEvents.SwiperBlockView, {
            ...options,
            id_item: idsString,
            index: $swiper.value?.swiper.activeIndex,
          });
        }
      } else {
        $addEvent(AnalyticsEvents.SwiperBlockView, {
          ...options,
          id_item: allIds.value[activeIndex],
          index: activeIndex,
        });
      }
    };
  }

  function clickEvent(opts: {id_item?: string | number, index?: number, item?: string, value?: string }) {
    const params = {
      ...options,
      id_item: opts.id_item,
      index: opts.index,
      value: opts.value,
    };

    if (opts.item) {
      params.item = opts.item;
    }

    $addEvent(AnalyticsEvents.SwiperBlockClick, params);
  }

  return {
    event,
    clickEvent,
  };
}
