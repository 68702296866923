<template>
  <div
    :class="{
      'osk-swiper': true,
      'osk-swiper_off': isOff,
    }"
  >
    <Swiper
      :key="`osk-swiper-${swiperKey}`"
      class="osk-swiper__block"
      :modules="modulesComp"
      :loop="swiperLoop"
      :slides-per-view="slidesPerView"
      :slides-per-group="slidesPerGroup"
      :space-between="spaceBetween"
      :autoplay="autoplay"
      :speed="speed"
      :navigation="navigationComp"
      :pagination="paginationComp"
      :breakpoints="breakpoints"
      :free-mode="freeMode"
      :allow-touch-move="allowTouchMove"
      :short-swipes="shortSwipes"
      :long-swipes-ms="longSwipesMs"
      :long-swipes-ratio="longSwipesRatio"
      :resistance-ratio="resistanceRatio"
      :thumbs="thumbs"
      :centered-slides="centeredSlides"
      :centered-slides-bounds="centeredSlidesBounds"
      :direction="direction"
      :mousewheel="mousewheel"
      :scrollbar="scrollbar"
      :zoom="zoom"
      :css-mode="cssMode"
      :auto-height="autoHeight"
      :loop-add-blank-slides="loopAddBlankSlides"
      @swiper="handleSwiper"
      @breakpoint="handleBreakpoint"
      @slide-change="handleSlideChange"
      @slide-next-transition-end="slideNextTransitionEnd"
      @slide-prev-transition-end="slidePrevTransitionEnd"
      @after-init="handleAfterInit"
      @touch-start="emits('touchStart', $event)"
      @touch-end="emits('touchEnd', $event)"
    >
      <slot />
    </Swiper>

    <template v-if="navigationComp">
      <div
        v-if="loadMore"
        class="osk-swiper__loader"
      >
        <UikitLoader small />
      </div>

      <div
        ref="prevRef"
        class="osk-swiper-button-prev"
        :class="{'osk-swiper-button-prev_arrowBack': arrowBack}"
      >
        <UikitIcon
          :name="arrowBack ? 'ArrowBack' : 'ArrowRight'"
          size="l"
        />
      </div>

      <div
        ref="nextRef"
        class="osk-swiper-button-next"
        :class="{'osk-swiper-button-next_arrowBack': arrowBack}"
      >
        <UikitIcon
          :name="arrowBack ? 'ArrowBack' : 'ArrowRight'"
          size="l"
        />
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/vue';
import {
  A11y,
  Navigation,
  Pagination,
  Autoplay as AutoplayModule,
  FreeMode as FreemodeModule,
  Thumbs as ThumbsModule,
  Mousewheel as MousewheelModule,
  Scrollbar as ScrollbarlModule,
  Zoom as ZoomModule,
} from 'swiper/modules';

import type {
  SwiperOptions,
  Swiper as SwiperClass,
  NavigationOptions,
  PaginationOptions,
  ThumbsOptions,
  ScrollbarOptions,
  ZoomOptions,
} from 'swiper/types';

interface ISwiperOptions extends SwiperOptions {
  off?: boolean
}

interface IBreakpoints {
  [width: number]: ISwiperOptions;
  [ratio: string]: ISwiperOptions;
}

interface AutoplayOptions {
  delay?: number;
  stopOnLastSlide?: boolean;
  disableOnInteraction?: boolean;
  reverseDirection?: boolean;
  waitForTransition?: boolean;
  pauseOnMouseEnter?: boolean;
}

interface FreemodeOptions {
  enabled?: boolean;
  minimumVelocity?: number;
  momentum?: boolean;
  momentumBounce?: boolean;
  momentumBounceRatio?: number;
  momentumRatio?: number;
  momentumVelocityRatio?: number;
  sticky?: boolean;
}

interface Props {
  off?: boolean
  loop?: boolean
  slidesPerView?: number | 'auto'
  slidesPerGroup?: number
  spaceBetween?: number
  autoplay?: AutoplayOptions | boolean
  navigation?: NavigationOptions | boolean
  pagination?: PaginationOptions | boolean
  breakpoints?: IBreakpoints
  freeMode?: FreemodeOptions | boolean,
  speed?: number,
  allowTouchMove?: boolean,
  centeredSlides?: boolean,
  centeredSlidesBounds?: boolean,
  longSwipesMs?: number
  longSwipesRatio?: number
  resistanceRatio?: number
  thumbs?: ThumbsOptions,
  direction?: 'horizontal' | 'vertical'
  mousewheel?: boolean
  scrollbar?: ScrollbarOptions
  zoom?: ZoomOptions
  cssMode?: boolean
  shortSwipes?: boolean
  arrowBack?: boolean
  autoHeight?: boolean
  loadMore?: boolean
  loopAddBlankSlides?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  loop: false,
  off: false,
  slidesPerView: 1,
  slidesPerGroup: 1,
  spaceBetween: 0,
  autoplay: false,
  navigation: false,
  pagination: false,
  breakpoints: undefined,
  freeMode: undefined,
  speed: 800,
  allowTouchMove: undefined,
  longSwipesMs: 100,
  longSwipesRatio: 0.1,
  resistanceRatio: 0.85,
  thumbs: undefined,
  direction: undefined,
  scrollbar: undefined,
  zoom: undefined,
  loopAddBlankSlides: false,
});

const emits = defineEmits<{
  (e: 'swiper', swiper: SwiperClass): void,
  (e: 'slideChange', swiper: SwiperClass): void,
  (e: 'slideNext', swiper: SwiperClass): void,
  (e: 'slidePrev', swiper: SwiperClass): void,
  (e: 'afterInit', swiper: SwiperClass): void,
  (e: 'touchStart', swiper: SwiperClass): void,
  (e: 'touchEnd', swiper: SwiperClass): void,
}>();

let breakpointStarted = false;
let prevWidth: number;
let nextWidth: number;

const isOff = ref(props.off);
const isNavEnabled = ref(false);
const swiperRef = ref<SwiperClass>();
const swiperKey = ref(0);
const swiperLoop = ref(false);
const prevRef = ref(null);
const nextRef = ref(null);

const modulesComp = computed(() => {
  const arr = [
    A11y,
    Navigation,
    Pagination,
  ];

  if (props.autoplay) {
    arr.push(AutoplayModule);
  }

  if (props.freeMode) {
    arr.push(FreemodeModule);
  }

  if (props.mousewheel) {
    arr.push(MousewheelModule);
  }

  if (props.scrollbar) {
    arr.push(ScrollbarlModule);
  }

  if (props.thumbs) {
    arr.push(ThumbsModule);
  }

  if (props.zoom) {
    arr.push(ZoomModule);
  }

  return arr;
});

const navigationComp = computed(() => {
  const nav = props.navigation;
  const controls = {
    prevEl: prevRef.value,
    nextEl: nextRef.value,
  };

  if (nav === true) {
    return {
      enabled: true,
      ...controls,
    };
  }

  if (nav) {
    return {
      ...(nav as NavigationOptions),
      ...controls,
    };
  }

  return nav;
});

const paginationComp = computed(() => {
  const pagin = props.pagination;

  return pagin === true ? { clickable: true } : pagin;
});

function reload() {
  const width = window.innerWidth;

  if ((prevWidth && width < prevWidth) || (nextWidth && width >= nextWidth)) {
    window.removeEventListener('resize', reload);

    updateSwiper();
    isOff.value = false;
  }
}

async function handleSwiper(swiper: SwiperClass) {
  swiperRef.value = swiper;

  emits('swiper', swiper);
}

async function handleSlideChange(swiper: SwiperClass) {
  emits('slideChange', swiper);
}

function updateSwiper() {
  breakpointStarted = false;
  swiperKey.value = swiperKey.value ? 0 : 1;
}

async function handleBreakpoint(swiper: SwiperClass, breakpointParams: ISwiperOptions) {
  if (breakpointParams.off && props.breakpoints) {
    const keys = Object.keys(props.breakpoints);
    const index = keys.indexOf(swiper.currentBreakpoint);

    prevWidth = Number(swiper.currentBreakpoint);
    nextWidth = Number(keys[index + 1]);

    await nextTick();

    isOff.value = true;
    isNavEnabled.value = false;

    swiper.destroy();

    window.addEventListener('resize', reload);
    return;
  }

  isOff.value = false;
  isNavEnabled.value = !!(breakpointParams.navigation as NavigationOptions)?.enabled;

  if (!breakpointStarted) {
    breakpointStarted = true;
    return;
  }

  updateSwiper();
}

function slideNextTransitionEnd(swiper: SwiperClass) {
  emits('slideNext', swiper);
}

function slidePrevTransitionEnd(swiper: SwiperClass) {
  emits('slidePrev', swiper);
}

function handleAfterInit(swiper: SwiperClass) {
  emits('afterInit', swiper);
}

onMounted(async () => {
  if (props.loop) {
    swiperLoop.value = true;
  }
});

watch(() => swiperLoop.value, async () => {
  if (!swiperRef.value?.destroyed) {
    await nextTick();

    updateSwiper();
  }
});

onUnmounted(() => {
  window.removeEventListener('resize', reload);
});

defineExpose({
  swiper: swiperRef,
});
</script>

<style lang="scss">
@import 'swiper/scss';
@import 'swiper/scss/a11y';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';
@import 'swiper/css/thumbs';
@import 'swiper/css/scrollbar';
@import 'swiper/css/zoom';

@import "~/assets/scss/settings/index";

.osk-swiper {
  position: relative;

  &__block {
    width: 100%;
  }

  .swiper-slide {
    height: auto;
  }

  .swiper-pagination {
    bottom: 4px;
  }

  .swiper-pagination-bullet {
    width: 7px;
    height: 7px;
    background: #000;
    opacity: 0.2;
    margin: 0 5px !important;
  }

  .swiper-pagination-bullet-active {
    opacity: 1;
  }

  .osk-swiper-button-prev,
  .osk-swiper-button-next {
    display: inline-flex;
    cursor: pointer;
    z-index: 1;
    position: absolute;
    top: -48px;
    right: 0;

    @include media-query(lg-and-up) {
      top: -63px;
    }

    .osk-icon {
      transition: all linear .2s;
    }
  }

  .osk-swiper-button-prev {
    transform: rotate(180deg);
    right: 40px;

    &_arrowBack {
      transform: rotate(0deg);
    }
  }

  .osk-swiper-button-next {
    &_arrowBack {
      transform: rotate(180deg);
    }
  }

  &__loader {
    position: absolute;
    right: 2px;
    top: -45px;
    display: flex;
    background-color: rgba(255, 255, 255, 0.8);
    width: 60px;
    height: 21px;
    z-index: 2;

    @include media-query(lg-and-up) {
      top: -63px;
    }

    .osk-loader {
      margin: auto;
    }
  }

  .swiper-button-disabled {
    .osk-icon {
      color: $grey-2;
      cursor: default;
    }
  }

  .swiper-navigation-disabled {
    & ~ .osk-swiper-button-prev,
    & ~ .osk-swiper-button-next {
      display: none !important;
    }
  }

  .swiper-button-lock {
    display: none !important;
  }

  &_off {
    .swiper-wrapper {
      box-sizing: border-box;
    }

    .osk-swiper-button-prev,
    .osk-swiper-button-next,
    .swiper-pagination {
      display: none !important;
    }
  }

  .swiper-css-mode > .swiper-wrapper {
    scrollbar-width: none;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      display: none !important;
    }

    &::-webkit-scrollbar-thumb {
      display: none;
    }
  }
}
</style>
