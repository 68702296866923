<template>
  <section
    ref="$block"
    class="osk-products-block"
  >
    <div class="osk-wrapper">
      <h2
        v-if="title"
        class="osk-products-block__title"
      >
        <span
          class="osk-products-block__title-text"
        >{{ title }}</span>

        <nuxt-link
          v-if="showMoreLink"
          :to="props.showMoreLink"
          class="osk-products-block__title-link"
          @click.prevent="goToNextPage"
        >
          <UikitIcon
            name="ArrowRight"
            size="l"
          />
        </nuxt-link>
      </h2>

      <UikitSwiper
        v-if="products.length"
        ref="$swiper"
        off
        navigation
        :css-mode="!isDesktop"
        :breakpoints="sliderBreakpoints"
        class="osk-products-block__swiper"
        @slide-next="handleSlideNext"
        @slide-prev="handleSlidePrev"
        @slide-change="handleSlideChange"
      >
        <UikitSwiperSlide
          v-for="(product, index) in products"
          :key="index"
        >
          <UikitProductCard
            :index="index"
            :product="product"
            :add-to-cart="addToCart"
            :chapter="chapter"
            :chapter-id="chapterId"
            :event-options="eventOptions"
            :use-swiper-analytics="useSwiperAnalytics"
            :is-seller-hidden="isSellerHidden"
            :analytics="analytics"
            @product-click="handleProductClick"
          />
        </UikitSwiperSlide>
      </UikitSwiper>

      <div
        v-if="showMoreLink"
        class="osk-products-block__footer"
      >
        <UikitButton
          class="osk-products-block__button"
          outline
          size="s"
          @click="goToNextPage"
        >
          {{ showMoreText ?? $t('common.showAll') }}
        </UikitButton>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { PrimaryPageProductDTO, ProductDTORes } from '~/restAPI/data-contracts';
import { AnalyticsEvents, IEventOptions, IOptions } from '~/types/analytics';

const props = defineProps<{
  title?: string
  products: (PrimaryPageProductDTO | ProductDTORes | PrimaryPageProductDTO)[]
  showMoreLink?: string
  showMoreText?: string
  addToCart?: boolean
  chapter?: string
  chapterId?: string
  eventOptions?: IEventOptions
  useSwiperAnalytics?: boolean
  isSellerHidden?: boolean
  analytics?: IOptions
}>();

const emits = defineEmits<{
  (e: 'showAll'): void,
}>();

const sliderBreakpoints = {
  0: {
    slidesPerView: 1.50,
    slidesPerGroup: 1,
    spaceBetween: 0,
    navigation: { enabled: false },
  },
  478: {
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 0,
    navigation: { enabled: false },
  },
  580: {
    slidesPerView: 2.5,
    slidesPerGroup: 2,
    spaceBetween: 0,
    navigation: { enabled: false },
  },
  700: {
    slidesPerView: 3,
    slidesPerGroup: 3,
    spaceBetween: 0,
    navigation: { enabled: false },
  },
  1024: {
    slidesPerView: 4,
    slidesPerGroup: 4,
    spaceBetween: 24,
    navigation: { enabled: true },
  },
};

const { $addEvent } = useNuxtApp();
const { isDesktop } = useUiBreakpoints();

const $block = ref<ComponentPublicInstance>();
const $swiper = ref();

function isProductDTORes(value: PrimaryPageProductDTO | ProductDTORes | PrimaryPageProductDTO): value is ProductDTORes {
  return !!(value as ProductDTORes).productId;
}

const allIds = computed(() => props.products.map((p) => {
  if (isProductDTORes(p)) {
    return p.productId!;
  }

  return p.id!;
}));

const { event, clickEvent } = useSwiperAnalytics(
  $swiper,
  $block,
  allIds,
  {
    chapter: props.analytics?.chapter || props.chapter,
    chapter_index: props.analytics?.chapter_index,
    segment_id: props.analytics?.segment_id,
    id_chapter: props.analytics?.id_chapter || props.chapterId,
    item: 'product',
  },
  sliderBreakpoints,
  props.useSwiperAnalytics,
);

async function goToNextPage() {
  if (!props.showMoreLink) return;

  $addEvent(AnalyticsEvents.ShowAllClick, {
    chapter: props.analytics?.chapter || props.chapter,
    chapter_index: props.analytics?.chapter_index,
    segment_id: props.analytics?.segment_id,
    id_chapter: props.analytics?.id_chapter || props.chapterId,
    ...(props.eventOptions?.showAll ?? []),
  });

  emits('showAll');

  await navigateTo(props.showMoreLink);
}

function handleSlideChange() {
  if (!props.useSwiperAnalytics) return;

  event();
}

function handleProductClick({ productId, index, value }: { productId?: number, index?: number, value?: string }) {
  if (!props.useSwiperAnalytics) return;

  clickEvent({
    id_item: String(productId),
    index,
    item: 'product',
    value,
  });
}

function handleSlideNext() {
  if (props.useSwiperAnalytics) return;

  $addEvent(AnalyticsEvents.SliderNext, {
    chapter: props.chapter,
    ...(props.eventOptions?.sliderArrows ?? []),
  });
}

function handleSlidePrev() {
  if (props.useSwiperAnalytics) return;

  $addEvent(AnalyticsEvents.SliderPrev, {
    chapter: props.chapter,
    ...(props.eventOptions?.sliderArrows ?? []),
  });
}
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

.osk-products-block {
  padding-bottom: 42px;

  @include media-query(lg-and-up) {
    padding-bottom: 82px;
    margin-bottom: 0;
  }

  &__title {
    @include font-style($font-size-heading, normal, $font-weight-bold);
    padding: 0 16px 22px;
    position: relative;

    @include media-query(lg-and-up) {
      text-transform: uppercase;
      text-align: center;
      padding-bottom: 34px;
      max-width: 1000px;
      margin: 0 auto;
    }
  }

  &__title-text {
    @include overflow-ellipsis;
    max-width: 90%;
    display: block;

    @include media-query(lg-and-up) {
      max-width: 100%;
    }
  }

  &__title-link {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-25px);

    @include media-query(lg-and-up) {
      display: none;
    }
  }

  .osk-wrapper {
    padding: 0;

    @include media-query(lg-and-up) {
      padding: 0 16px;
    }
  }

  .swiper-slide {
    padding: 0 16px;
    margin-right: -20px;

    @include media-query(lg-and-up) {
      padding: 0;
      margin: 0;
    }
  }

  .catalog-product-item {
    margin-bottom: 0;
    height: 100%;
  }

  .osk-swiper_off {
    margin-bottom: 0;
    height: 100%;

    .swiper-wrapper {
      overflow: visible;
      justify-content: space-between;
    }

    .swiper-slide {
      width: 100%;
      max-width: 282px !important;
      margin-right: 24px;
      flex-shrink: 0;
    }
  }

  @include swiper-section-nav;

  .osk-swiper-button-next {
    @include media-query(lg-and-up) {
      right: 0;
    }
  }

  .osk-swiper-button-prev {
    @include media-query(lg-and-up) {
      right: 38px;
    }
  }

  &__footer {
    display: none;

    @include media-query(lg-and-up) {
      display: flex;
      padding-top: 42px;
    }
  }

  &__button {
    margin: 0 auto;
    max-width: 100%;
    min-width: 188px;
  }
}
</style>
